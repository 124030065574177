import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SelfieCapture.sass";
import FaceAutoCapture from "../FaceAutoCapture/FaceAutoCapture";

interface Props {
    onResponse: ( message:any ) => void;
}

const SelfieCapture = ({onResponse} : Props) => {
    const { t } = useTranslation();
    const [selfieBase64, setSelfieBase64] = useState<string | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [userState, setUserState] = useState<string>('face')
    // const [faceBase64, setFaceBase64] = useState<string>()
    //
    // const handleResponse = (message: any) => {
    //     if (message.face) {
    //         setUserState(message.step)
    //         setFaceBase64(message.face)
    //     }
    // }

    const handlePhotoTaken = (base64Photo: string) => {
        onResponse({
          step: 'selfieDone',
          face: base64Photo
        })
    }

    const reCapturePhoto = () => {
        setSelfieBase64(null)
    }

    const handleUserResponse = (message:string) => {
        if (message === 'selfieOk') {
            onResponse({
                step: 'selfieDone',
                face: selfieBase64
            })

            setUserState('selfieDone')
        } else {
            reCapturePhoto()
        }
    }

    const handleError = (error: Error) => {
        console.error(error)
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const togglePanel = () => {
        setIsPanelOpen((prev) => !prev);
    };

    return (
        <div className={`container-selfie-capture`}>
            {!selfieBase64 && (
                <div className="container-selfie-capture-inside">
                    <div className={`${isModalOpen ? "blur-background" : ""}`}>
                        <FaceAutoCapture onPhotoTaken={handlePhotoTaken} onError={handleError} isModalOpen={isModalOpen}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelfieCapture;
