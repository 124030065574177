import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './arenaTemplate.sass'

import { ResponsivePie } from '@nivo/pie'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'
import templateArena from '../assets/images/template-arena.png'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


import usert1 from '../assets/images/user-t1.jpg'
import usert2 from '../assets/images/user-t2.jpg'
import usert3 from '../assets/images/user-t3.jpg'
import usert4 from '../assets/images/user-t4.jpg'
import usert5 from '../assets/images/user-t5.jpg'
import usert6 from '../assets/images/user-t6.jpg'
import usert7 from '../assets/images/user-t7.jpg'
import usert8 from '../assets/images/user-t8.jpg'
import usert9 from '../assets/images/user-t9.jpg'
import usert10 from '../assets/images/user-t10.jpg'
import usert11 from '../assets/images/user-t11.jpg'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';
import { DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { formatDate, formatDay } from 'react-calendar/dist/cjs/shared/dateFormatter';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: number,
  last_sync: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_error,
   };
}


const EventArenaEntrance = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [turnstiles, setTurnstiles] = React.useState<any>([]);


  const showFace = () => {
      const deu = Math.random() < 0.5 ? true:false;
      if(deu){
        const qual = Math.floor(Math.random() * 3) + 1;
        if(qual === 1){
          return {selfie: usert1, status: 'arena-entrance'}
        }else if(qual === 2){
          return {selfie: usert2, status: 'arena-entrance'}
        }else if(qual === 3){
          return {selfie: usert3, status: 'arena-entrance'}
        }else if(qual === 4){
          return {selfie: usert4, status: 'arena-entrance'}
        }else if(qual === 5){
          return {selfie: usert5, status: 'arena-entrance'}
        }else if(qual === 6){
          return {selfie: usert6, status: 'arena-entrance'}
        }else if(qual === 7){
          return {selfie: usert7, status: 'arena-entrance'}
        }else if(qual === 8){
          return {selfie: usert8, status: 'arena-entrance'}
        }else if(qual === 9){
          return {selfie: usert9, status: 'arena-entrance'}
        }else if(qual === 10){
          return {selfie: usert10, status: 'arena-entrance'}
        }
        return {selfie: usert11, status: 'arena-entrance'}
      }

      const deunao = Math.random() < 0.07 ? true:false;

      if(deunao){
        return {selfie: null, status: 'arena-denied'}
      }
      return {selfie: null, status: 'arena-idle'}
  }

  const [itemsNorte, setItemsNorte] = React.useState<any>([]);
  const [itemsOeste, setItemsOeste] = React.useState<any>([]);
  const [itemsLest, setItemsLest] = React.useState<any>([]);
  const [itemsSul, setItemsSul] = React.useState<any>([]);



  useEffect(() => {
    setInterval(() => {
      
    setItemsLest(Array.from({ length: 44 }, (_, i) => {i + 1
      return{
          label: `B-${i}P`,
          ...showFace()
        } 
    }))
    setItemsNorte(Array.from({ length: 50 }, (_, i) => {i + 1
      return{
          label: `B-${i}P`,
          ...showFace()
        } 
    }))
    setItemsOeste(Array.from({ length: 20 }, (_, i) => {i + 1
      return{
          label: `B-${i}P`,
          ...showFace()
        } 
    })) // Total de 56 itens
    setItemsSul(Array.from({ length: 49 }, (_, i) => {i + 1
      return{
          label: `B-${i}P`,
          ...showFace()
        } 
    }))
    }, 3000);
  
  }, [])



  // lest 44
  // norte 50
  // oeste 20
  // sul 49
  const getPosition = (i: number) => {
    // Configurações das fileiras
    const rowStartIndices = [1, 17, 32]; // Início das fileiras
    const rowItemCounts = [15, 14, 12]; // Quantidade de itens por fileira

    let rowIndex = 0;
    while (i >= rowStartIndices[rowIndex + 1]) {
      rowIndex++;
    }

    const rowStart = rowStartIndices[rowIndex]; // Início da fileira
    const positionInRow = i - rowStart; // Posição na fileira
    const rowItemCount = rowItemCounts[rowIndex]; // Total na fileira

    // Calculando `x` e `y` dentro da parábola
    const relativeX = (positionInRow - (rowItemCount - 1) / 2) * 1.5; // Ajustando espaçamento
    const y = relativeX * relativeX * 0.08 + rowIndex * 6 + 9; // Curvatura com deslocamento vertical
    const left = `calc(47% + ${relativeX * 2}%)`;
    const top = `${y}%`;

    return { left, top };
  };

  const getPositionNorte = (i: number) => {
    // Configurações das fileiras
    const rowStartIndices = [1, 15, 28, 40]; // Início das fileiras
    const rowItemCounts = [14, 13, 12, 11]; // Quantidade de itens por fileira

    let rowIndex = 0;
    while (i >= rowStartIndices[rowIndex + 1]) {
      rowIndex++;
    }

    const rowStart = rowStartIndices[rowIndex]; // Início da fileira
    const positionInRow = i - rowStart; // Posição na fileira
    const rowItemCount = rowItemCounts[rowIndex]; // Total na fileira

    // Calculando `x` e `y` dentro da parábola
    const relativeX = (positionInRow - (rowItemCount - 1) / 2) * 2.4; // Ajustando espaçamento
    const y = relativeX * relativeX * 0.065 + rowIndex * 4.3 + 4.3; // Curvatura com deslocamento vertical
    const top = `calc(56% + ${relativeX * 2.2}%)`;
    const left = `${y}%`;

    return { left, top };
  };

  const getPositionOeste = (i: number) => {
    // Configurações das fileiras
    const rowStartIndices = [1, 11]; // Início das fileiras
    const rowItemCounts = [10, 10]; // Quantidade de itens por fileira

    let rowIndex = 0;
    while (i >= rowStartIndices[rowIndex + 1]) {
      rowIndex++;
    }

    const rowStart = rowStartIndices[rowIndex]; // Início da fileira
    const positionInRow = i - rowStart; // Posição na fileira
    const rowItemCount = rowItemCounts[rowIndex]; // Total na fileira

    // Calculando `x` e `y` dentro da parábola
    const relativeX = (positionInRow - (rowItemCount - 1) / 2) * 2; // Ajustando espaçamento
    const y = relativeX * relativeX * -0.07 + rowIndex * 5 + 91; // Curvatura com deslocamento vertical
    const left = `calc(50% + ${relativeX * 2.1}%)`;
    const top = `${y}%`;

    return { left, top };
  };

  const getPositionSul = (i: number) => {
    // Configurações das fileiras
    const rowStartIndices = [1, 11, 23, 36]; // Início das fileiras
    const rowItemCounts = [10, 12, 13, 14]; // Quantidade de itens por fileira

    let rowIndex = 0;
    while (i >= rowStartIndices[rowIndex + 1]) {
      rowIndex++;
    }

    const rowStart = rowStartIndices[rowIndex]; // Início da fileira
    const positionInRow = i - rowStart; // Posição na fileira
    const rowItemCount = rowItemCounts[rowIndex]; // Total na fileira

    // Calculando `x` e `y` dentro da parábola
    const relativeX = (positionInRow - (rowItemCount - 1) / 2) * 2.3; // Ajustando espaçamento
    const y = relativeX * relativeX * -0.055 + rowIndex * 5 + 80; // Curvatura com deslocamento vertical
    const top = `calc(55% + ${relativeX * 2.2}%)`;
    const left = `${y}%`;

    return { left, top };
  };




  return (

    <Grid container spacing={2}>


    <Grid size={8}>
    <Box className="arena-template-wrapper">
    <Typography variant="h2">Catracas em tempo real</Typography>
    <ul className="arena-template-turnstile">
    {itemsLest.map((item:any, index:any) => {
        const { left, top } = getPosition(index + 1);
        return (
          <li
            className={item.status}
            key={index}
            style={{
              backgroundImage: `url(${item.selfie})`,
              left,
              top,
            }}
          >

<Typography  variant="caption">{item.label}</Typography>

          </li>
        );
      })}

{itemsNorte.map((item:any, index:any) =>  {
        const { left, top } = getPositionNorte(index + 1);
        return (
          <li
            className={item.status}
            key={`2${index}`}
            style={{
              backgroundImage: `url(${item.selfie})`,
              left,
              top,
            }}
          >

<Typography  variant="caption">{item.label}</Typography>

          </li>
        );
      })}
{itemsOeste.map((item:any, index:any) =>  {
        const { left, top } = getPositionOeste(index + 1);
        return (
          <li
            className={item.status}
            key={`3${index}`}
            style={{
              backgroundImage: `url(${item.selfie})`,
              left,
              top,
            }}
          >

<Typography  variant="caption">{item.label}</Typography>

          </li>
        );
      })}
{itemsSul.map((item:any, index:any) =>  {
        const { left, top } = getPositionSul(index + 1);
        return (
          <li
            className={item.status}
            key={`4${index}`}
            style={{
              backgroundImage: `url(${item.selfie})`,
              left,
              top,
            }}
          >

<Typography  variant="caption">{item.label}</Typography>

          </li>
        );
      })}
    </ul>
      <img src={templateArena} alt="" />
  </Box>
      </Grid>
      <Grid size={4} className="arena-counters">
      <Box className="arena-entrance-counter">
        <Typography variant="h1">11827</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '45%', mr: 1 }}>
        <LinearProgress variant="determinate" value={20} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >entradas - {`${Math.round(10)}%`}</Typography>
      </Box>
    </Box>
        </Box>
        <Box className="arena-entrance-counter waiting">
        <Typography variant="h1">22829</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '55%', mr: 1 }}>
        <LinearProgress variant="determinate" value={20} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >Faltam - {`${Math.round(10)}%`}</Typography>
      </Box>
    </Box>
        </Box>

        
        <Box className="arena-graph-entrances">
        <ResponsivePie
        data={[
          {
            "id": "Entraram",
            "label": "Entraram",
            "value": 29091,
            "color": "hsl(346, 99%, 40%)"
          },
          {
            "id": "Faltam",
            "label": "Faltam",
            "value": 9019,
            "color": "hsl(112, 70%, 50%)"
          }
        ]}
        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        colors={['#64c800', '#c90131']}
        defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -60,
              lineWidth: 6,
              spacing: 8
            }
        ]}
        fill={[
          {
            match: {
                id: 'Faltam'
            },
            id: 'lines'
          },{
            match: {
                id: 'Entraram'
            },
            id: 'lines'
          }
        ]}
    />
        </Box>
      
      
        <Box className="default-box">

      <Typography variant="h2">
        Acessos por minuto
      </Typography>
      
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Hota</TableCell>
            <TableCell>Acessos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => (  ))}*/}
            <TableRow
            >
              <TableCell>
                20:20
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

            <TableRow
            >
              <TableCell>
                20:20
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

            <TableRow
            >
              <TableCell>
                20:20
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

            <TableRow
            >
              <TableCell>
                20:20
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>


        </Box>


        <Box className="default-box">
        <Typography variant="h2">
        Supervisor
      </Typography>
      <Typography className='big-numbers' variant="caption">20</Typography>


      <Typography variant="h2">
        Alertas
      </Typography>
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {/* {rows.map((row) => (  ))}*/}
            <TableRow
            >
              <TableCell>
                Dupla entrada
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

            <TableRow
            >
              <TableCell>
                Portão errado
              </TableCell>
              <TableCell align="right">222</TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
        </Box>



      </Grid>
    </Grid>


  )
};

export default EventArenaEntrance;