import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';


import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent} from '../Components/ApiCall';



type FormData = {
  id: number;
  server_name: string;
  server_ip: string;
  server_tag: string;
  status: number;
  mac: string;
  ip_type: string;
  image_version: string;
  evento: string;
  ip: string;
  last_conf: string;
  ip_vpn: string;
  ip_wan: string;
  vpn: number;
  gw_default: string;
  dns1: string;
  dns2: string;
  vpn_file: string;
};

const columns: GridColDef<any>[] = [
  {
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
    renderCell: (params) => {
      return <Box>{params.value} <StarIcon onClick={() => console.log(params.id)} /></Box>  ;
    },
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 110,
    editable: true,
  },{
    field: 'server_name',
    headerName: 'Servidor',
    width: 150,
    editable: true,
  },{
    field: 'company',
    headerName: 'Cliente',
    width: 150,
    editable: true,
  },{
    field: 'action',
    headerName: 'Ação',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return params.value === 'star' ? <StarIcon onClick={() => console.log(params.id)} /> : params.value === 'thumb' ? <ThumbUpIcon /> : null;
    },
  },
];




const Servers = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);
    
      try {
        const response = await apiEvent.post('/servers', {
            serverName: data.server_name,
            serverIp: data.server_ip,
            serverTag: data.server_tag,
            companyId: '9ab01cb1-8966-4199-8c68-bca27027a709',
            eventId: '068011c4-f3ca-4e7a-820d-38e279c67d95'
        });
        console.log(response.data);

        setSuccess(true);
        setLoading(false);
        fetchData()

        setTimeout(() => {
          setSuccess(false);
          handleClose()
        }, 2000);
    
        // const dataValues = response.data.map((item:any, index:any) => {
        //   return {
        //     id: ++index,
        //     bebox_name: item.serverTag,
        //     server_name: item.serverName,
        //     company: 'Bepass',
        //     last_time_online: item.updatedAt,
        //     action: 'star'
        //   }
        // })
        // setRola(dataValues)
        // setVai(false)
    
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };

  const [rola, setRola] = useState<any>([])
  const [vai, setVai] = useState<boolean>(true)
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {
  try {
    const response = await apiEvent.get('/servers');
    console.log(response.data);

    const dataValues = response.data.map((item:any, index:any) => {
      return {
        id: ++index,
        bebox_name: item.serverTag,
        server_name: item.serverName,
        company: 'Bepass',
        last_time_online: item.updatedAt,
        action: 'star'
      }
    })
    setRola(dataValues)
    setVai(false)

  } catch (error) {
    console.error(error);
  }
};



useEffect(() => {
  fetchData()
}, [])

  return (
    <Box>

      <Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          Servidores Bepass
        </Typography>

        <Button variant="contained" onClick={() => handleOpen()}>Cadastrar Novo</Button>


        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Cadastrar Servidor
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleClose()}>
        <CloseIcon
        />
        </i>
      </div>
      </Box>

<form className='modal-form-content' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Server Name"
            {...register("server_name", { required: "Nome é obrigatório!" })}
            error={!!errors.server_name}
            helperText={errors.server_name?.message}
            fullWidth
          />
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Server IP"
            {...register("server_ip", { required: "IP é obrigatório" })}
            error={!!errors.server_ip}
            helperText={errors.server_ip?.message}
            fullWidth
          />
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
        <TextField
            label="Server Tag"
            {...register("server_tag", { required: "Tag é obrigatória" })}
            error={!!errors.server_tag}
            helperText={errors.server_tag?.message}
            fullWidth
          />
        </Grid>
       
        <Grid  size={{ xs: 12, md: 6 }}>
          {/* <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button> */}

<Box sx={{ m: 1, position: 'relative' }}>

{success ?

        <Box
        className="icon-success"
        >
         <CheckIcon />
         Salvo!
        </Box> :        <Button
          variant="contained"
          disabled={loading}
          type='submit'
          // onClick={handleButtonClick}
        >
          Accept terms
        </Button>}

        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: "#f0f",
              position: 'absolute',
              top: '-50%',
              left: '40%',

            }}
          />
        )}
      </Box>
        </Grid>
      </Grid>
    </form>

        </Box>
      </Modal>

      </Box>


      <Box className="data-table-wrapper">
      
<DataGrid
        rows={rola}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[3]}
        checkboxSelection
        disableRowSelectionOnClick
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(updatedRow, originalRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        loading={vai}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
      />
      </Box>
    </Box>
  )
};

export default Servers;