import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}


const TerminalGroups = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [rola, setRola] = useState<any>([[],[]])


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)

    try {
      const response = await apiUser.post('/biometry/find/user/byDocument',
        {
          userId: data.document.replace(/\./g, '').replace(/-/g, ''),
          companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
          userSearchType: 'document',
          userType: 'game'
        }
      );


      if (response.data.data && response.data.data.length === 0) {

        console.log('oiee!')

        setNotFound(true)
        setLoading(false);

        setTimeout(() => {
          setNotFound(false)
        }, 3000);
        return false
      }
      setUserProfile(response.data.data[0])
      setDisplayUserProfile(true)

      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error(error);
    }


    console.log('Form data submitted:', data);
  };


  
  const rows = [
    createData(14000, 524, 12100, 12100, 24, "17/12/2024 11:15", "17/12/2024 11:16", 109, 0),
  ];

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = async (data:any) => {

    const response = await apiEvent.put(`/terminals/${data.target.name}`, {
      deviceGroup: data.target.value,
    });
    fetchData()
  }

  const columns: GridColDef<any>[] = [
    { 
      field: 'device_ip',
      headerName: 'Device IP',
      width: 110,
      editable: true,
    },{ 
      field: 'deviceName',
      headerName: 'tag',
      width: 120,
      editable: true,
    },{
      field: 'device_group',
      headerName: 'Grupo',
      width: 210,
      editable: true,
renderCell: (params) => {
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Age</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={params.value}
          label="Age"
          name={params.row.id}
          defaultValue={params.value}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="carneiros_dia">Carneiros dia</MenuItem>
          <MenuItem value="carneiros_noite">Carneiros Noite</MenuItem>
        </Select>
      </FormControl>
      )
    },
    },{
      field: 'bebox_version',
      headerName: 'Versão bebox',
      width: 120,
      editable: true,
    },{
      field: 'device_firmware',
      headerName: 'Firmware',
      width: 150,
      editable: true,
    },{
      field: 'device_type',
      headerName: 'Tipo',
      width: 90,
      editable: true,
    },{
      field: 'last_time_online',
      headerName: 'Última vez online',
      width: 220,
      sortable: false,
    }
  ];
  
  const fetchData = async () => {
    try {
      const response = await apiEvent.get('/terminals');
      console.log(response.data);

      const devices_dia:any = [],
      devices_noite:any = [];
  
      const dataValues = response.data.map((item:any, index:any) => {

        if(item.deviceGroup === 'carneiros_dia'){
          devices_dia.push({
            id: item.id,
            bebox_name: item.beboxName || 'lab_s2',
            server_name: item.serverName,
            device_name: item.deviceName,
            sector: item.sector,
            gate: item.gate,
            device_ip: item.deviceIP,
            device_firmware: item.deviceFirmware,
            bebox_version: item.beboxVersion,
            device_type: item.deviceType,
            device_group: item.deviceGroup,
            last_time_online: item.updatedAt,
            action: 'star',
            status: item.status,
            beboxIP: item.beboxIP,
            deviceName: item.deviceName,
            beboxName: item.beboxName,
          })}else{
            devices_noite.push({
              id: item.id,
              bebox_name: item.beboxName || 'lab_s2',
              server_name: item.serverName,
              device_name: item.deviceName,
              sector: item.sector,
              gate: item.gate,
              device_ip: item.deviceIP,
              device_firmware: item.deviceFirmware,
              bebox_version: item.beboxVersion,
              device_type: item.deviceType,
              device_group: item.deviceGroup,
              last_time_online: item.updatedAt,
              action: 'star',
              status: item.status,
              beboxIP: item.beboxIP,
              deviceName: item.deviceName,
              beboxName: item.beboxName,
            })
          }
        
        return {
          id: item.id,
          bebox_name: item.beboxName || 'lab_s2',
          server_name: item.serverName,
          device_name: item.deviceName,
          sector: item.sector,
          gate: item.gate,
          device_ip: item.deviceIP,
          device_firmware: item.deviceFirmware,
          bebox_version: item.beboxVersion,
          device_type: item.deviceType,
          device_group: item.deviceGroup,
          last_time_online: item.updatedAt,
          action: 'star',
          status: item.status,
          beboxIP: item.beboxIP,
          deviceName: item.deviceName,
          beboxName: item.beboxName,
        }
      })
      setRola([devices_dia, devices_noite])
      // setVai(false)
  
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])
  return (



    <Box>

<Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          Grupos de terminais
        </Typography>

        <Box>
        <Button variant="contained" onClick={() => console.log('oie')}>Cadastrar Novo</Button>
        </Box>


        <Modal
        open={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>po</>
      </Modal>

      </Box>



    <Accordion className='accordion-sync'>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box className="accordion-sync-symmary">
            Beach Club
            <ExpandMoreIcon />
          </Box>
          <ul className="accordion-sync-summary-list">

           {[{
              count: rola[0].length,
              label: 'Dispositivos cadastrados'
            }].map((item, index) => (
             <li key={index}>
             <Typography variant="h3" gutterBottom>
               {item.count}
               <span>{item.label}</span>
             </Typography>
           </li>
           ))}
           <li>
           <Button type="submit" variant="contained" color="primary">Adicionar Dispositivo</Button>
           </li>
          </ul>
        </AccordionSummary>
        
        
        <AccordionDetails>
        <Box className="data-table-wrapper">
      
      <DataGrid
              rows={rola[0]}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20]}
              checkboxSelection
              disableRowSelectionOnClick
              // processRowUpdate={(updatedRow, originalRow) =>
              //   processRowUpdate(updatedRow, originalRow)
              // }
              // onProcessRowUpdateError={handleProcessRowUpdateError}
              // loading={vai}
              slotProps={{
                loadingOverlay: {
                  variant: 'linear-progress',
                  noRowsVariant: 'skeleton',
                },
              }}
            />
            </Box>
        </AccordionDetails>
      </Accordion>







      <Accordion className='accordion-sync'>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box className="accordion-sync-symmary">
            Festa Noturna
            <ExpandMoreIcon />
          </Box>
          <ul className="accordion-sync-summary-list">

           {[{
              count: rola[1].length,
              label: 'Dispositivos cadastrados'
            }].map((item, index) => (
             <li key={index}>
             <Typography variant="h3" gutterBottom>
               {item.count}
               <span>{item.label}</span>
             </Typography>
           </li>
           ))}
           <li>
           <Button type="submit" variant="contained" color="primary">Adicionar Dispositivo</Button>
           </li>
          </ul>
        </AccordionSummary>
        
        
        <AccordionDetails>
        <Box className="data-table-wrapper">
      
      <DataGrid
              rows={rola[1]}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20]}
              checkboxSelection
              disableRowSelectionOnClick
              // processRowUpdate={(updatedRow, originalRow) =>
              //   processRowUpdate(updatedRow, originalRow)
              // }
              // onProcessRowUpdateError={handleProcessRowUpdateError}
              // loading={vai}
              slotProps={{
                loadingOverlay: {
                  variant: 'linear-progress',
                  noRowsVariant: 'skeleton',
                },
              }}
            />
            </Box>
        </AccordionDetails>
      </Accordion>



    </Box>
  )
};

export default TerminalGroups;