import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, Skeleton, Snackbar, Stack, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './userCard.sass'
import Modal from '@mui/material/Modal';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import demo1 from '../../assets/images/demo.jpg'
import demo2 from '../../assets/images/demo2.jpg'
import demo3 from '../../assets/images/demo3.jpg'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CheckIcon from '@mui/icons-material/Check';

import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

const steps = ['Biometria', 'Frente do documento', 'Verso do documento', 'Conclusão'];


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { apiUser } from '../ApiCall';

const UserCardLoader = () => {
  
  return (
    <Card className="user-card" variant="outlined">
      <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      <Skeleton variant="text" sx={{ width: '200px' }} />
      </Typography>
      </Box>
      <Box>
<ul className="user-card-content-details">
        <li><Skeleton variant="text" sx={{ width: '200px' }} /></li>
        <li><Skeleton variant="text" sx={{ width: '200px' }} /></li>
        <li><Skeleton variant="text" sx={{ width: '200px' }} /></li>
        </ul>
</Box>
      <CardContent>

        <Grid container spacing={2}>


<Grid size={3}>
  <div className="user-card-image-wrap solo-view">
    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
  </div>
</Grid>
<Grid size={3}>
  <div className="user-card-image-wrap solo-view">
    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
  </div>
</Grid>
<Grid size={3}>
  <div className="user-card-image-wrap solo-view">
    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
  </div>
</Grid>
<Grid size={3}>
  <Box className="user-card-info-action">
<Typography variant="h4" noWrap component="div">
      Status
      </Typography>
      <Skeleton variant="text" sx={{ width: '100%' }} />
      <Skeleton variant="text" sx={{ width: '100%' }} />
      <Skeleton variant="text" sx={{ width: '100%' }} />
      </Box>

      <br />
      <br />
      <br />

      <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" />
      <br />
      <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" />
      <br />
      <Skeleton sx={{ height: 30 }} animation="wave" variant="rectangular" />

</Grid>

</Grid>

      </CardContent>

    </Card>
  )
};

export default UserCardLoader;