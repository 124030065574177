import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, Chip, Link, Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './app.sass'

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { APPLICATION } from './config/application';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Home from './Pages/Home';
import Terminals from './Pages/Terminals';
import Servers from './Pages/Servers';
import Sidebar from './Components/Sidebar/Sidebar';
import Login from './Pages/Login';
import Event from './Pages/Event';
import EventArenaEntrance from './Pages/EventArenaEntrance';
import Besafe from './Pages/Besafe';
import Activations from './Pages/Activations';
import EventSync from './Pages/EventSync';
import GameSync from './Pages/GameSync';
import InternalTerminals from './Pages/InternalTerminals';
import TerminalGroups from './Pages/TerminalGroup';
import EventConfiguration from './Pages/EventConfiguration';
import Users from './Pages/Users';
import ResetPassword from './Pages/ResetPassword';


const Vrau = () => {
  const { t } = useTranslation();
  return <h1>{t('vaiplaneta')}</h1>;
};


const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const lingua: any = window.sessionStorage.getItem('language')
    i18n.changeLanguage(lingua);
  }, [])

  return (
    <div>
      <Button variant="contained">Hello world</Button>;
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('uai')}>Mineires</button>
    </div>
  );
};



const App = () => {


  const [currentPage, setCurrentPage] = useState('inside')
  const [showComeBack, setShowComeBack] = useState(false)

  const [application, setApplication] = useState(3)

useEffect(() => {

  const getUserName: any = window.sessionStorage.getItem('name')
  setApplication(Number(window.sessionStorage.getItem('application_id')) || 3)
  setUserName(getUserName || '-')
  const currentWindow = window.location.pathname;

  console.log(currentWindow)

  if(currentWindow === '/stadium'){
    setShowComeBack(true)
  }
  if(currentWindow !== '/' && !/reset-password/.test(currentWindow)){
    return setCurrentPage('inside')
  }else{
    setCurrentPage('login')
  }
},[])

const [userName, setUserName] = useState('inside')



  return (
<Box className={`${currentPage === 'login' && 'login-wrapper'}`} sx={{ display: 'flex' }}>
  {currentPage === 'inside' && (
    <>
      <CssBaseline />
  <AppBar
    position="fixed"
    sx={{ width: `calc(100% - 240px)`, ml: `240px` }}
  >
    <Toolbar
      className='custom-toolbar'
      sx={{ alignItems: "space-between" }}>
        {showComeBack && (
          <Chip 
          label="Voltar"
          variant="outlined"
          icon={<KeyboardArrowLeftOutlinedIcon />}
          onClick={() => window.location.href = '/home'} />
        )}
         
      <Typography variant="h1" noWrap component="div">
        Dashboard
      </Typography>

      <Box className="header-login">
        <Stack
          spacing={2} direction="row"
          sx={{
            alignItems: 'center',
          }}
        >
          <Box className="header-login-info">
            <Typography variant="h6" noWrap component="div">{userName}</Typography>            <Typography className="header-login-label" noWrap>
              {/* meu perfil */}

              <span onClick={() => {
                sessionStorage.clear();
                window.location.href = '/'
              }}>
                logout
              </span>

              <ChevronRightIcon />
            </Typography>
          </Box>
          <Avatar>R</Avatar>

        </Stack>
      </Box>

    </Toolbar>
  </AppBar>

    </>
  )}

  <Box
    component="main"
    className={`${currentPage ==='inside' ? 'main-wrapper':'main-wrapper-login'}`}
  >

<Router>
      {/* <LanguageSwitcher /> */}
      <Routes>
        <Route path="/terminals" element={
          <>
            <Sidebar application={application} />
            <Terminals />
          </>
        } />
        <Route path="/devices/groups" element={
          <>
            <Sidebar application={application} />
            <TerminalGroups/>
          </>
        } />
        <Route path="/devices" element={
          <>
            <Sidebar application={application} />
            {/* <Terminals /> */}
            <InternalTerminals/>
          </>
        } />
        <Route path="/servers" element={
          <>
            <Sidebar application={application} />
            <Servers />
          </>
        } />
        <Route path="/home" element={
          <>
            <Sidebar application={application} />
            <Home />
          </>
        } />
        <Route path="/game" element={
          <>
            <Sidebar application={application} />
            <GameSync />
          </>
        } />
        <Route path="/event" element={
          <>
            <Sidebar application={application} />
            <Event/>
          </>
        } />
        <Route path="/event/config" element={
          <>
            <Sidebar application={application} />
            <EventConfiguration/>
          </>
        } />
        <Route path="/users" element={
          <>
            <Sidebar application={application} />
            <Users />
          </>
        } />
        <Route path="/stadium" element={
            <Box className="inside-jobs">
              <EventArenaEntrance />
            </Box>
        } />
        <Route path="/besafe" element={
          <>
          <Sidebar application={application} />
          <Besafe />
        </>
        } />
        <Route path="/activations" element={
          <>
          <Sidebar application={application} />
          <Activations />
        </>
        } />
        <Route path="/" element={
                <Login />
            } />

            <Route path="/reset-password/:userToken" element={
                <ResetPassword />
            } />
      </Routes>
    </Router>
  </Box>
</Box>
  );
}

export default App;