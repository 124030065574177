import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, LinearProgress, ListItemAvatar, MenuItem, OutlinedInput, Pagination, PaginationItem, Select, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './besafe.sass'
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';

import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import usert2 from '../assets/images/user-t2.jpg'

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent, apiUser} from '../Components/ApiCall';
import UserBiometryImage from '../Components/UserCard/UserBiometryImage';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import BeSafeImageItem from '../Components/Besafe/BeSafeImageItem';


type FormData = {
  id: number;
  hostname: string;
  serial_number: string;
  config_date: string;
  status: number;
  mac: string;
  ip_type: string;
  image_version: string;
  evento: string;
  ip: string;
  last_conf: string;
  ip_vpn: string;
  ip_wan: string;
  vpn: number;
  gw_default: string;
  dns1: string;
  dns2: string;
  vpn_file: string;
};



const Besafe = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);
    
      try {
        const response = await apiEvent.post('/terminals', {
          id: data.id,
          hostname: data.hostname,
          serial_number: data.serial_number,
          config_date: data.config_date,
          status: data.status,
          mac: data.mac,
          ip_type: data.ip_type,
          image_version: data.image_version,
          evento: data.evento,
          ip: data.ip,
          last_conf: data.last_conf,
          ip_vpn: data.ip_vpn,
          ip_wan: data.ip_wan,
          vpn: data.vpn,
          gw_default: data.gw_default,
          dns1: data.dns1,
          dns2: data.dns2,
          vpn_file: data.vpn_file
        });
        console.log(response.data);

        setSuccess(true);
        setLoading(false);
        fetchData()

        setTimeout(() => {
          setSuccess(false);
          handleClose()
        }, 2000);
    
        // const dataValues = response.data.map((item:any, index:any) => {
        //   return {
        //     id: ++index,
        //     bebox_name: item.serverTag,
        //     server_name: item.serverName,
        //     company: 'Bepass',
        //     last_time_online: item.updatedAt,
        //     action: 'star'
        //   }
        // })
        // setRola(dataValues)
        // setVai(false)
    
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };

  const [rola, setRola] = useState<any>([])
  const [vai, setVai] = useState<boolean>(true)
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {
  try {
    const response = await apiEvent.get('/terminals');
    console.log(response.data);

    const dataValues = response.data.map((item:any, index:any) => {
      return {
        id: ++index,
        bebox_name: item.beboxName || 'lab_s2',
        server_name: item.serverName,
        company: 'Bepass',
        last_time_online: item.updatedAt,
        action: 'star'
      }
    })
    setRola(dataValues)
    setVai(false)

  } catch (error) {
    console.error(error);
  }
};


const getTerminalInfra = async (bebox_name:string) => {
  try {
    const response = await apiEvent.get(`/terminals/infra-detail/${bebox_name}`);
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};


const columns: GridColDef<any>[] = [
  {
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
    renderCell: (params) => {
      return <Box>{params.value} <StarIcon onClick={() => console.log(params.id)} /></Box>  ;
    },
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 110,
    editable: true,
  },{
    field: 'server_name',
    headerName: 'Servidor',
    width: 150,
    editable: true,
  },{
    field: 'company',
    headerName: 'Cliente',
    width: 150,
    editable: true,
  },{
    field: 'action',
    headerName: 'Ação',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return params.value === 'star' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : params.value === 'thumb' ? <ThumbUpIcon /> : null;
    },
  },
];





useEffect(() => {
  fetchData()
}, [])

const [displayUserProfile, setDisplayUserProfile] = useState(false)
const [userProfile, setUserProfile] = useState({})
const [notFound, setNotFound] = React.useState(false);

const [filteredData, setFilteredData] = useState({
  par_code: null,
  gate: null
})

const [reportData, setReportData] = React.useState({
  tickets: 0,
  faces: 0,
  items: []
});

const [currentReportData, setCurrentReportData] = React.useState([]);

const closeUserSearchBox = () => {
  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
}

const reloadUserData = (document:any) => {
  // onSubmit({
  //   id: 1,
  //   document
  // })
  return true
}

const fetchReportData = async (data:any) => {

  try {
    const response = await apiEvent.post('/event/besafe', 
      filteredData
    );

    console.log('hoo', response.data[1])
    if(response.data){
      const _faceCount = response.data[0].map((item:any) => item.facial_url)
      setReportData({
        tickets: response.data[1],
        faces: _faceCount.length,
        items: response.data[0]
      })

      setCurrentReportData(response.data[0].slice(0, 24))

      return false
    }
  } catch (error) {
    console.error(error);
  }

  console.log(data)
  return true
}

const getFiltredData = (data:any) => {
  console.log(data.target)
  const _prevFilter:any = filteredData;
  _prevFilter[data.target.name] = `${data.target.value}`
  setFilteredData(_prevFilter)
  fetchReportData(_prevFilter)

  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
  return true
}


const paginationHandler = (event: React.ChangeEvent<unknown>, value: number) => {

  setCurrentReportData(reportData.items.slice(value*24, (value*24)+24))
};


const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  
  setNotFound(false)

  setReportData({
    tickets: 0,
    faces: 0,
    items: []
  })
  setCurrentReportData([])

  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const reader = new FileReader();
    console.log('29292')
    reader.onloadend = async () => {
      console.log('aosdoasd')
      const base64String = reader.result as string;

      try {
        const responseMatch = await apiUser.post('/biometry/saffe/match', 
          {
            image: base64String
          }
        );
        
        console.log(responseMatch.data)

        if(responseMatch.data.match === true){
          const docResponse =  responseMatch.data.user.replace('bp', '')
          try {
            const response = await apiUser.post('/biometry/find/user/byDocument', 
              {
                userId: docResponse.replace(/\./g, '').replace(/-/g, ''),
                companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
                userSearchType: 'document',
                userType: 'game'
              }
            );
    
            
            setReportData({
              tickets: 1,
              faces: 1,
              items: []
            })
            setUserProfile(response.data.data[0])
            setDisplayUserProfile(true)
    
            setSuccess(true);
            setLoading(false);
    
            setTimeout(() => {
              setSuccess(false);
            }, 2000);
        
          } catch (error) {
            console.error(error);
          }
        }else{
          setUserProfile({})
          setDisplayUserProfile(false)
          setNotFound(true)
        }


        return true
      } catch (error) {
        console.error(error);
        return false
      }
    }
    reader.readAsDataURL(file);
  }
  
};


  return (
    <Box>

      <Box className="header-with-button">
        <Typography variant="subtitle1" component="h2">
          Be.Safe
        </Typography>
        <Typography variant="caption" >
          Programa de seguraça Bepass. Escolha um evento, portaria, catraca ou suba uma foto para tentarmos uma compração.
        </Typography>
        </Box>
  
        <Grid className="graph-biometries-counter besafe-wrapper" container spacing={2}>

<Grid size={3}>
  <Typography variant="h3" gutterBottom>
    Selecione por partida
  </Typography>

    
<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="choose-event-label">Partida</InputLabel>
    <Select
      labelId="choose-event-label"
      id="choose-event"
      name="par_code"
      label="Escolher"
    onChange={getFiltredData}
    >
      <MenuItem value={20241208}>08/12/2024 - Flamengo	vs Vitória</MenuItem>
      <MenuItem value={20241201}>01/12/2024 - Flamengo	vs Internacional</MenuItem>
    </Select>
  </FormControl>
</Box>

<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="choose-gate-label">Portaria</InputLabel>
    <Select
      labelId="choose-gate-label"
      id="choose-gate"
      name="gate"
      label="Escolher"
    onChange={getFiltredData}
    >
      <MenuItem value={18810}>Leste Inferior</MenuItem>
      <MenuItem value={99944}>Leste Superior</MenuItem>
      <MenuItem value={99125}>Maracanã Mais</MenuItem>
      <MenuItem value={98713}>Norte Nível 1 E</MenuItem>
      <MenuItem value={98639}>Norte Nível 1 F</MenuItem>
      <MenuItem value={99447}>Norte Nível 2 E</MenuItem>
      <MenuItem value={99308}>Norte Nível 2 F</MenuItem>
      <MenuItem value={98962}>Sul Nível 1 C</MenuItem>
      <MenuItem value={99619}>Sul Nível 2 C</MenuItem>
      <MenuItem value={101074}>Visitante</MenuItem>
    </Select>
  </FormControl>
</Box>

<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker label="Data / hora" />
      </DemoContainer>
    </LocalizationProvider>

<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Catraca</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      label="Escolher"
    // onChange={handleChange}
    >
      <MenuItem value={0}>D-34PNE</MenuItem>
      <MenuItem value={0}>C-11T</MenuItem>
      <MenuItem value={0}>E-01PCD</MenuItem>
      <MenuItem value={0}>A-16T</MenuItem>
      <MenuItem value={0}>A-29P</MenuItem>
      <MenuItem value={0}>C-18P</MenuItem>
      <MenuItem value={0}>E-20P</MenuItem>
      <MenuItem value={0}>E-22P</MenuItem>
      <MenuItem value={0}>A-17T</MenuItem>
      <MenuItem value={0}>E-16T</MenuItem>
      <MenuItem value={0}>F-14T</MenuItem>
      <MenuItem value={0}>B-04T</MenuItem>
      <MenuItem value={0}>C-19P</MenuItem>
      <MenuItem value={0}>A-11T</MenuItem>
      <MenuItem value={0}>C-27P</MenuItem>
      <MenuItem value={0}>A-20T</MenuItem>
      <MenuItem value={0}>D-27T</MenuItem>
    </Select>
  </FormControl>
</Box>



<Button
        variant="contained"
        disabled={loading}
        onClick={() => console.log('oie')}
        className='besafe-select-form-button'
      >
        Pesquisar
      </Button>
  </Grid>

  <Grid size={3}>
    
    <Box className="besafe-image-uploader">
      Pesquisar por foto
      <PhotoCameraFrontOutlinedIcon/>

      <input className="controls-input"
          type="file"
          id="docfront-uploader"
          accept="image/*"
          name='selfie'
          onChange={handleImageUpload}
        /> 
    </Box>
  </Grid>


  {notFound ? (
 <Typography variant="h1" gutterBottom>
 Nenhum resultado encontrado
</Typography>

  ):(
    <Grid size={3}>


    <Typography variant="h3" gutterBottom>
      Ingressos Filtrados
    </Typography>
  
  
  
          <Box className="arena-entrance-counter waiting">
          <Typography variant="h1">{reportData.tickets}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '55%', mr: 1 }}>
          <LinearProgress variant="determinate" value={20} />
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >ingressos</Typography>
        </Box>
      </Box>
          </Box>
  
  
          <Box className="arena-entrance-counter">
          <Typography variant="h1">{reportData.faces}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '45%', mr: 1 }}>
          <LinearProgress variant="determinate" value={20} />
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >pessoas</Typography>
        </Box>
      </Box>
          </Box>
  
    </Grid>
 )}


  </Grid>


  {loading && (
    <UserCardLoader/>
  )}
  {displayUserProfile && (
    <>
    <UserCard userData={userProfile} handleCloseBox={closeUserSearchBox} handleRefresh={reloadUserData} />
    </>
  )}


  <Grid className="graph-biometries-counter besafe-wrapper" container spacing={2}>
    {
      currentReportData.map((item:any, index) => (
<Grid key={index} size={2}>
    <BeSafeImageItem imagePath={item.facial_url} userData={{document: item.document, name: `${item.first_name} ${item.last_name}`, hash: item.ing_hash }} />
      {/* <UserBiometryImage imageSide="selfie" imagePath={'resized/000001e6-a21d-4bf8-9d01-96f4d39f6b75/202348184836/000001e6-a21d-4bf8-9d01-96f4d39f6b75.jpg'} /> */}
    </Grid>
      )) 
    }
  </Grid>

    {(currentReportData && currentReportData.length > 5) && (
  <Stack spacing={2} className='pagination-wrapper'>
  <Pagination
    count={10}
    onChange={paginationHandler}
    renderItem={(item) => (
      <PaginationItem
        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
        {...item}
      />
    )}
  />
</Stack>
    )}

    </Box>
  )
};

export default Besafe;