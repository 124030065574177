import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, LinearProgress, ListItemAvatar, MenuItem, OutlinedInput, Pagination, PaginationItem, Select, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './besafe.sass'
import './activations.sass'

import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';



import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';

import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import usert2 from '../assets/images/user-t2.jpg'

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, CheckCircleOutline, CheckCircleOutlineRounded, DateRangeRounded, EditOutlined, PersonPinCircle } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent, apiJaiminho} from '../Components/ApiCall';
import UserBiometryImage from '../Components/UserCard/UserBiometryImage';
import UserCardLoader from '../Components/UserCard/UserCardLoader';



type FormData = {
  game: string;
  gate: string;
  turnstile: string;
  gender: string;
  age: string;
  activation: string;
  campain: string;
  message: string;
};



const Activations = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    console.log('--->')
    console.log(data)
    console.log('<< --  -')
    setSuccess(false);
    setLoading(true);
    

      try {

        const response = await apiJaiminho.post('/jaiminho', {
          "subject": data.campain,
          "template": reportData.faces,
          "companyId": '0',
          "eventId": "22",
          "eventDate":  "2024-10-10",
          "eventTime":  "10:10",
          "supplierId":  "222",
          "sendEmail":  false,
          "sendSms":  false,
          "sendWhats":  false
          });
        console.log(response.data);

        setSuccess(true);
        setLoading(false);
        fetchData()

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
    
    
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };

  const [rola, setRola] = useState<any>([])
  const [vai, setVai] = useState<boolean>(true)

  const [jaiminhoMessages, setJaiminhoMessages] = useState<any>([])
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {
  try {
    const response = await apiJaiminho.get('/jaiminho');
    console.log(response.data);
    if(response.data){
      setJaiminhoMessages(response.data)
    }


  } catch (error) {
    console.error(error);
  }
};


const getTerminalInfra = async (bebox_name:string) => {
  try {
    const response = await apiEvent.get(`/terminals/infra-detail/${bebox_name}`);
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};


const columns: GridColDef<any>[] = [
  {
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
    renderCell: (params) => {
      return <Box>{params.value} <StarIcon onClick={() => console.log(params.id)} /></Box>  ;
    },
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 110,
    editable: true,
  },{
    field: 'server_name',
    headerName: 'Servidor',
    width: 150,
    editable: true,
  },{
    field: 'company',
    headerName: 'Cliente',
    width: 150,
    editable: true,
  },{
    field: 'action',
    headerName: 'Ação',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return params.value === 'star' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : params.value === 'thumb' ? <ThumbUpIcon /> : null;
    },
  },
];





useEffect(() => {
  fetchData()
}, [])

const [displayUserProfile, setDisplayUserProfile] = useState(false)
const [userProfile, setUserProfile] = useState({})
const [notFound, setNotFound] = React.useState(false);

const closeUserSearchBox = () => {
  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
}

const reloadUserData = (document:any) => {
  // onSubmit({
  //   id: 1,
  //   document
  // })
  return true
}


const [filteredData, setFilteredData] = useState({
  par_code: null,
  gate: null
})

const [reportData, setReportData] = React.useState({
  tickets: 0,
  faces: 0,
  items: []
});


const fetchReportData = async (data:any) => {

  try {
    const response = await apiEvent.post('/event/besafe', 
      filteredData
    );

    console.log('hoo', response.data[1])
    if(response.data){
      const _faceCount = response.data[0].map((item:any) => item.facial_url)
      setReportData({
        tickets: response.data[1],
        faces: _faceCount.length,
        items: response.data[0]
      })


      return false
    }
  } catch (error) {
    console.error(error);
  }

  console.log(data)
  return true
}


const getFiltredData = (data:any) => {

  if(data.target.value === 666){
    setReportData({
      tickets: 1,
      faces: 1,
      items: []
    })
    return false
  }
  console.log(data.target)
  const _prevFilter:any = filteredData;
  _prevFilter[data.target.name] = `${data.target.value}`
  setFilteredData(_prevFilter)
  fetchReportData(_prevFilter)

  console.log('oieee')
  setUserProfile({})
  setDisplayUserProfile(false)
  setNotFound(false)
  return true
}


  return (
    <Box>

      <Box className="header-with-button">
        <Typography variant="subtitle1" component="h2">
          Ativações
        </Typography>
        <Typography variant="caption" >
          Permite enviar mensagens personalizadas através de ativações pré-programadas
        </Typography>
        </Box>
  
        <form className='user-search-form' onSubmit={handleSubmit(onSubmit)}>
        <Grid className="graph-biometries-counter besafe-wrapper" container spacing={2}>

<Grid size={3}>
  <Typography variant="h3" gutterBottom>
    Escolha os filtros
  </Typography>


<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Partida</InputLabel>
    <Select
      labelId="choose-event-label"
      id="choose-event"
      name="par_code"
      label="Escolher"
    onChange={getFiltredData}
    >
      <MenuItem value={20241208}>08/12/2024 - Flamengo	vs Vitória</MenuItem>
      <MenuItem value={20241201}>01/12/2024 - Flamengo	vs Internacional</MenuItem>
      <MenuItem value={666}>Teste Bepass</MenuItem>
    </Select>
  </FormControl>
</Box>
    
<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Portaria</InputLabel>
    <Select
      labelId="choose-gate-label"
      id="choose-gate"
      name="gate"
      label="Escolher"
    onChange={getFiltredData}
    >
      <MenuItem value={18810}>Leste Inferior</MenuItem>
      <MenuItem value={99944}>Leste Superior</MenuItem>
      <MenuItem value={99125}>Maracanã Mais</MenuItem>
      <MenuItem value={98713}>Norte Nível 1 E</MenuItem>
      <MenuItem value={98639}>Norte Nível 1 F</MenuItem>
      <MenuItem value={99447}>Norte Nível 2 E</MenuItem>
      <MenuItem value={99308}>Norte Nível 2 F</MenuItem>
      <MenuItem value={98962}>Sul Nível 1 C</MenuItem>
      <MenuItem value={99619}>Sul Nível 2 C</MenuItem>
      <MenuItem value={101074}>Visitante</MenuItem>
    </Select>
  </FormControl>
</Box>


<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Catraca</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      label="Escolher"
    // onChange={handleChange}
    >
      <MenuItem value={0}>D-34PNE</MenuItem>
      <MenuItem value={0}>C-11T</MenuItem>
      <MenuItem value={0}>E-01PCD</MenuItem>
      <MenuItem value={0}>A-16T</MenuItem>
      <MenuItem value={0}>A-29P</MenuItem>
      <MenuItem value={0}>C-18P</MenuItem>
      <MenuItem value={0}>E-20P</MenuItem>
      <MenuItem value={0}>E-22P</MenuItem>
      <MenuItem value={0}>A-17T</MenuItem>
      <MenuItem value={0}>E-16T</MenuItem>
      <MenuItem value={0}>F-14T</MenuItem>
      <MenuItem value={0}>B-04T</MenuItem>
      <MenuItem value={0}>C-19P</MenuItem>
      <MenuItem value={0}>A-11T</MenuItem>
      <MenuItem value={0}>C-27P</MenuItem>
      <MenuItem value={0}>A-20T</MenuItem>
      <MenuItem value={0}>D-27T</MenuItem>
    </Select>
  </FormControl>
</Box>

  </Grid>

  <Grid size={3}>
  <Typography variant="h3" gutterBottom>
    _
  </Typography>


<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Gênero</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      label="Escolher"
    // onChange={handleChange}
    >
      <MenuItem value={10}>Masculino</MenuItem>
      <MenuItem value={20}>Feminino</MenuItem>
      <MenuItem value={30}>Outro</MenuItem>
    </Select>
  </FormControl>
</Box>
    
<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Idade</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      label="Escolher"
    // onChange={handleChange}
    >
      <MenuItem value={10}>0 - 11 anos</MenuItem>
      <MenuItem value={20}>12 - 18 anos</MenuItem>
      <MenuItem value={30}>19 - 25 anos</MenuItem>
      <MenuItem value={30}>26 - 35 anos</MenuItem>
      <MenuItem value={30}>35 anos +</MenuItem>
    </Select>
  </FormControl>
</Box>


    
<Box className="event-select">
<FormControl  fullWidth>
    <InputLabel id="demo-simple-select-label">Ativação</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      // value={age}
      label="Escolher"
    // onChange={handleChange}
    >
      <MenuItem value={10}>Na catraca</MenuItem>
      <MenuItem value={20}>1 hora antes</MenuItem>
      <MenuItem value={30}>5 minutos depois</MenuItem>
      <MenuItem value={40}>1 hora depois</MenuItem>
    </Select>
  </FormControl>
</Box>

  </Grid>

  <Grid size={3}>

  <Typography variant="h3" gutterBottom>
    _
  </Typography>
  <Box className="event-select">
  <TextField
            label="Campanha"
            {...register("campain", { required: "Campanha é obrigartória" })}
            error={!!errors.campain}
            helperText={errors.campain?.message}
            fullWidth
          />
  </Box>




        <TextField
            className='activation-form-textarea'
            label="Mensagem"
            placeholder='{{1}},

O {{2}} te dá boas-vindas ao {{3}}!

A sua presença torna esse evento ainda mais único.

Para {{4}}, segurança e agilidade são prioridades inegociáveis!'
            fullWidth
            multiline
            rows={2}
          />

  <Button
        variant="contained"
        disabled={loading}
        type='submit'
        className='besafe-select-form-button'
      >
        Gerar
      </Button>
    </Grid>
  
  <Grid size={3}>


  <Typography variant="h3" gutterBottom>
    Pessoas filtradas
  </Typography>

        <Box className="arena-entrance-counter waiting">
        <Typography variant="h1">{reportData.tickets}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '55%', mr: 1 }}>
        <LinearProgress variant="determinate" value={20} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >ingressos</Typography>
      </Box>
    </Box>
        </Box>


        <Box className="arena-entrance-counter">
        <Typography variant="h1">{reportData.faces}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '45%', mr: 1 }}>
        <LinearProgress variant="determinate" value={20} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >pessoas</Typography>
      </Box>
    </Box>
        </Box>

  </Grid>


  </Grid>
  </form>

  {loading && (
    <UserCardLoader/>
  )}
  {displayUserProfile && (
    <>
    <UserCard userData={userProfile} handleCloseBox={closeUserSearchBox} handleRefresh={reloadUserData} />
    </>
  )}


<List
className='activations-list'
     
      subheader={<ListSubheader>Todas as ativações</ListSubheader>}
    >
          {
      jaiminhoMessages.map((item:any, index:any) => (
        <>
      <ListItem key={index}>
        <ListItemIcon>
          {/* <CheckCircleOutlineRounded /> */}
          <Switch
          edge="end"
          // onChange={handleToggle('bluetooth')}
          defaultChecked
          inputProps={{
            'aria-labelledby': 'switch-list-label-bluetooth',
          }}
        />
        </ListItemIcon>
        <ListItemText id="switch-list-label-wifi" primary={item.subject} />
        <ListItemText id="rola" primary={`Público: ${item.template}`} />
        <ListItemText id="rola" primary={`Impactados: ${item.companyId}`} />
        <ListItemIcon>
          <DownloadingOutlinedIcon />
        </ListItemIcon>
      </ListItem>
  
      </>
       )) 
      }
    </List>
{/* 
  <Stack spacing={2} className='pagination-wrapper'>
      <Pagination
        count={10}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack> */}
    </Box>
  )
};

export default Activations;