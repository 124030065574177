import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import visitorLogo from '../assets/images/client_not_set.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}


const GameSync = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})




  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)

    try {
      const response = await apiUser.post('/biometry/find/user/byDocument',
        {
          userId: data.document.replace(/\./g, '').replace(/-/g, ''),
          companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
          userSearchType: 'document',
          userType: 'game'
        }
      );


      if (response.data.data && response.data.data.length === 0) {

        console.log('oiee!')

        setNotFound(true)
        setLoading(false);

        setTimeout(() => {
          setNotFound(false)
        }, 3000);
        return false
      }
      setUserProfile(response.data.data[0])
      setDisplayUserProfile(true)

      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error(error);
    }


    console.log('Form data submitted:', data);
  };


  
  const rows = [
    createData(0, 0, 0, 0, 0, "N/A", "N/A", 0, 0),
  ];

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = () => {
    console.log('oie')
  }

  const [companydata, setCompanydata] = React.useState({
    name: 'Bepass',
    logo: 'https://storage.googleapis.com/companies-assets/bepass/bepass_logo_default.png'
  });

  
  useEffect(() => {
    setCompanydata({
      name: sessionStorage.getItem('company_name') || '',
      logo: sessionStorage.getItem('company_logo') || ''
    })
  })
  return (



    <Box>

      <Grid className="graph-biometries-counter" container spacing={2}>

        <Grid size={9}>
          <Typography variant="h3" gutterBottom>
            Selecione por partida
          </Typography>

    <Box className="event-select">
    <FormControl  fullWidth>
            <InputLabel id="demo-simple-select-label">Escolher</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Escolher"
            // onChange={handleChange}
            >
              <MenuItem value={10}>Nenhuma partida no momento</MenuItem>
            </Select>
          </FormControl>
    </Box>
          


          <Box className="event-main-display">
            <Box className="action-row">
            <i><InsertChartOutlinedIcon /></i>
            </Box>

            <Typography variant="h1" noWrap component="h1">
              {/* Maracanã • 23/11/2024 - 20:00 */}
              - -
            </Typography>

            <Box className="event-card-game">
              <Box className="game-club">
                <img src={companydata.logo} alt="" />
              </Box>

              <Box className="game-event-details">
                <Typography variant="h3" noWrap component="h3">
                  {companydata.name}
                </Typography>
                <Typography variant="caption" noWrap component="span">
                 vs
                </Typography>
                <Typography variant="h3" noWrap component="h3">
                  - -
                </Typography>
              </Box>

              <Box className="game-club">
                <img src={visitorLogo} alt="" />
              </Box>
            </Box>

      <Box className="button-group">
      <Button disabled type="submit" variant="contained" color="primary">
            Baixar tickets
      </Button>
      <Button  type="submit" disabled variant="contained" color="primary">
        iniciar Partida
      </Button>
      </Box>


          </Box>


        </Grid>

        <Grid size={2}>
       {/*    <Typography variant="h3" gutterBottom>
            Data do evento
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
        />
         open={true}
         closeOnSelect={false} */}
    
  {/*   </LocalizationProvider>

         <DesktopDatePicker
          onChange={handleChange}
          /> */}
        </Grid>
      </Grid>


    <Box className="event-sync-symmary">
      <Typography variant="h2">
        Resumo
      </Typography>
      
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ingressos vendidos</TableCell>
            <TableCell>Ingressos cancelados</TableCell>
            <TableCell>Ingressos sincronizados</TableCell>
            <TableCell>Faces únicas</TableCell>
            <TableCell>CPF em duplicidade</TableCell>
            <TableCell>Último ingresso recebido</TableCell>
            <TableCell>Última sincronização</TableCell>
            <TableCell>Fila de sincronização</TableCell>
            <TableCell>Erro de sincronização</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.tickets}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.tickets}
              </TableCell>
              <TableCell align="right">{row.cancelled}</TableCell>
              <TableCell align="right">{row.synked}</TableCell>
              <TableCell align="right">{row.unique}</TableCell>

              <TableCell align="right">{row.duplicity}</TableCell>
              <TableCell align="right">{row.last_ticket}</TableCell>
              <TableCell align="right">{row.last_sync}</TableCell>
              <TableCell align="right">{row.sync_waiting}</TableCell>
              <TableCell align="right">{row.sync_error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>

    <Box className="event-evolution-chart">
    <Typography variant="h3" gutterBottom>
            Mapa de sincronização
          </Typography>

          <Box className="event-evolution-chart-wrapper">

          <ResponsiveLine
        data={[
          {
            "id": "japan",
            "color": "hsl(99, 70%, 50%)",
            "data": [
              {
                "x": "10:00",
                "y": 0
              },
              {
                "x": "10:15",
                "y": 0
              },
              {
                "x": "10:30",
                "y": 0
              },
              {
                "x": "10:45",
                "y": 0
              },
              {
                "x": "11:00",
                "y": 0
              },
              {
                "x": "11:15",
                "y": 0
              },
              {
                "x": "11:30",
                "y": 0
              },
              {
                "x": "11:45",
                "y": 0
              },
              {
                "x": "12:00",
                "y": 0
              },
              {
                "x": "12:15",
                "y": 0
              },
              {
                "x": "12:30",
                "y": 0
              },
              {
                "x": "12:45",
                "y": 0
              }
            ]
          },
          {
            "id": "france",
            "color": "hsl(58, 70%, 50%)",
            "data": [
              {
                "x": "10:00",
                "y": 0
              },
              {
                "x": "10:15",
                "y": 0
              },
              {
                "x": "10:30",
                "y": 0
              },
              {
                "x": "10:45",
                "y": 0
              },
              {
                "x": "11:00",
                "y": 0
              },
              {
                "x": "11:15",
                "y": 0
              },
              {
                "x": "11:30",
                "y": 0
              },
              {
                "x": "11:45",
                "y": 0
              },
              {
                "x": "12:00",
                "y": 0
              },
              {
                "x": "12:15",
                "y": 0
              },
              {
                "x": "12:30",
                "y": 0
              },
              {
                "x": "12:45",
                "y": 0
              }
            ]
          }
        ]}
        margin={{ top: 32, right: 16, bottom: 50, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        defs={[
          {
            id: 'opt1',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#3D6C00' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          },{
            id: 'opt2',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#529000' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          }
        ]}
        fill={[
            {
                match: {
                    id: 'japan'
                },
                id: 'opt1'
            },
            {
                match: {
                    id: 'france'
                },
                id: 'opt2'
            }
        ]}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        pointSize={0}
        pointBorderWidth={2}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
    />
    

          </Box>
          <Box className="event-evolution-chart-legend ticket-sold">
          <HorizontalRuleOutlinedIcon />
          ingressos vendidos
          </Box>
          <Box className="event-evolution-chart-legend ticket-sync">
          <HorizontalRuleOutlinedIcon />
          ingressos sincronizados
          </Box>
    </Box>

    <Box className="sync-summary-header">
    <Typography variant="h2" gutterBottom>
            Dispositivos
          </Typography>
          <ul className="sync-symmary-status">
            <li className='status-idle'>sincronizado</li>
            <li className='status-waiting'>sinc em andamento</li>
            <li className='status-offline'>off-line</li>
          </ul>

          <Button type="submit" variant="contained" color="primary">Atualizar</Button>
    </Box>



    <Accordion className='accordion-sync'>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box className="accordion-sync-symmary">
            - -
            <ExpandMoreIcon />
          </Box>
          <ul className="accordion-sync-summary-list">

           {[{
              count: '0%',
              label: 'Catracas sincronizadas'
            },{
              count: '0',
              label: 'Ingressos sincronizados'
            },{
              count: '0',
              label: 'Recebidos'
            },{
              count: '0',
              label: 'Erros de sinc'
            }].map((item, index) => (
             <li key={index}>
             <Typography variant="h3" gutterBottom>
               {item.count}
               <span>{item.label}</span>
             </Typography>
           </li>
           ))}
          </ul>
        </AccordionSummary>
        
        
        <AccordionDetails>
          {/* <ul className="accordion-sync-list">
          {[{
            device: 'D-01PCD', 
            count:'12.100',
            status: 'idle'
          },{
            device: 'D-02T', 
            count:'11.322',
            status: 'waiting'
          },{
            device: 'D-03T', 
            count:'0',
            status: 'offline'
          },{
            device: 'D-04T', 
            count:'0',
            status: 'offline'
          }].map((item, index) => (
             <li key={index} className={item.status}>
             <Typography variant="h3" gutterBottom>
               <span>{item.device}</span>
               {item.count}
             </Typography>
           </li>
           ))}
          </ul> */}
        </AccordionDetails>
      </Accordion>




    </Box>
  )
};

export default GameSync;