import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, Skeleton, Snackbar, Stack, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Grid2';

import './besafeimageItem.sass'

import Modal from '@mui/material/Modal';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import demo1 from '../../assets/images/demo.jpg'
import demo2 from '../../assets/images/demo2.jpg'
import demo3 from '../../assets/images/demo3.jpg'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CheckIcon from '@mui/icons-material/Check';

import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

const steps = ['Biometria', 'Frente do documento', 'Verso do documento', 'Conclusão'];


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { apiUser } from '../ApiCall';

export interface UserBiometryProps {
  imagePath: any
  userData: any
}

const BeSafeImageItem = ({imagePath, userData}:UserBiometryProps) => {

  const [imageItem, setImageItem] = useState<any>();
  const [imageLoader, setImageLoader] = useState(true);

  console.log(imagePath)

  const getUsersBiometryImage = async() => {

    try {
      const response = await apiUser.post('/biometry/getimageByPath', 
        {
          path: imagePath
        }
      );
      console.log(response.data);
      setImageItem(response.data?.image || 'na')
      setImageLoader(false)
    
    
    } catch (error) {
      console.error(error);
    }
    
    }


    console.log('ooooo lalalaie')


useEffect(() => {
    setImageLoader(true)
    getUsersBiometryImage()
}, [imagePath])


return(
  <Box className="besafe-image-wrap">
    {imageLoader ? (
      <Skeleton sx={{ height: 265 }} animation="wave" variant="rectangular" />
    ):(
      <>
    <img
    src={`data:image/png;base64,${imageItem}`}
    alt={`Documento`}
    className="document-image"
    />
    <Box className="besafe-image-description">
      <List>
      {userData.rate && (
        <ListItem><span>Precisão:</span> {userData.rate}%</ListItem>
      )}
        <ListItem><span>Nome:</span> {userData.name}</ListItem>
        <ListItem><span>Documento:</span>{userData.document}</ListItem>
        <ListItem><span>Ingresso:</span> {userData.hash}</ListItem>
      </List>
    </Box>
      </>
    )}
  </Box>



)

};

export default BeSafeImageItem;