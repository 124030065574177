import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button,IconButton, InputAdornment, FormHelperText , CircularProgress, InputLabel, OutlinedInput, Stack, Link, Modal, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'

import { useLocation } from 'react-router-dom';

import { signInWithEmailAndPassword } from "firebase/auth";

import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';

import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';


import clientLogo from '../assets/images/client_logo.png'

import bepassLogo from '../assets/images/bepass-default-logo.png'

import bepassIdLogo from '../assets/images/bepass-id-logo.png'

import { useForm, SubmitHandler } from 'react-hook-form';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';




import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';
import { CloseOutlined, DateRangeRounded, ErrorOutlineRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

import './login.sass'
import { apiCompany, apiEvent } from '../Components/ApiCall';
import SelfieCapture from '../Components/SelfieCapture/SelfieCapture';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import ConfettiCanvas from '../Components/Conffeti/Conffeti';
import { auth } from '../config/firebase';
import { useParams } from 'react-router-dom';

type Value = ValuePiece | [ValuePiece, ValuePiece];


type FormData = {
  password: string;
  confirm_password: string;
};


const ResetPassword = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;

  let { userToken } = useParams();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get('email');
  
  const [loginStep, setLoginStep] = React.useState(1);
  const [applicationId, setApplicationId] = React.useState(3);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  

  
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [cpfBepassIdHandler, setCpfBepassIdHandler] = React.useState('');
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<any> = async (data) => {
    setSuccess(false);
    setLoading(true);
    setError(false)
try{

  const sessionItem:any = window.sessionStorage.getItem('user-key')
  let { user_key, tenant_id } = sessionItem ? JSON.parse(sessionItem): {tenant_id: null, user_key: null}

    const updateUserPassword: any = await apiCompany.post(`/firebase/reset/password`,
      {
        "email": userEmail,
        "token": userToken,
        "password": data.password
      },
     {
        headers: {
          'x-api-key': userToken,
          'x-tenant-id': tenant_id
        }
      }
    )

    setSuccess(true);
    setLoading(false);
    setError(false)
  }catch(e){
    setError(true)
    setLoading(false)
  }
  return false

  };


  const setCompany = (tenantId:any) => {
    sessionStorage.setItem('user-key', JSON.stringify({user_key: null, tenant_id: tenantId}))
  }

  const [selfieCaptureHandler, setSelfieCaptureHandler] = React.useState(false);
  const [loginUsername, setLoginUsername] = React.useState('Olá! :)');
  const [bepassIdSteps, setBepassIdSteps] = React.useState('taking');

  const handleResponse = (oie:any) => {

    setBepassIdSteps('loading')

    setTimeout(() => {

      if(cpfBepassIdHandler !== '35325926810'){
        return setBepassIdSteps('error')
      }

      setBepassIdSteps('completed')

        setTimeout(() => {
          handleClose();

          setSuccess(false);
          setLoading(false);
          setError(false)
        }, 4000);
    }, 4000);

    if(oie.step === 'selfieDone'){
      console.log('meu grande peri')
    }else{
      console.log('aiaiai')
    }
  }


  useEffect(()=>{
    const currentUrl = window.location.href
    if(/app-ingresse/.test(currentUrl)){
      setCompany('ingresse-yyyho');setLoginStep(2);
    }
  },[])

  

  return (
    // bg change test ${bgChange ? 'peru':''}
    <Grid rowSpacing={3} className={`login-box`} container  justifyContent="right" alignItems={'center'}>

      {loginStep === 1 ? (

        <Grid  className="login-box-wrapper" size={{xl: 3, lg: 4, md: 5, sm: 6}}>

<img src={bepassLogo} alt="$" className="login-client-logo" />
<Typography variant="h2" gutterBottom>
Digite sua nova senha para continuar :)
</Typography>


<form className='login-form-content' onSubmit={handleSubmit(onSubmit)}>
  <Grid container spacing={2}>
    <Grid  size={12}>
      <TextField
        label="Senha"
        {...register("password", { required: "Senha é obrigatória" })}
        error={!!errors.password}
        helperText={errors.password?.message}
        fullWidth
      />
    </Grid>
    <Grid  size={12}>
      <TextField
        label="Senha"
        {...register("confirm_password", { required: "Confirmação da Senha é obrigatória" })}
        error={!!errors.confirm_password}
        type='password'
        helperText={errors.confirm_password?.message}
        fullWidth
      />
    </Grid>

    <Grid  size={12}>


<Box sx={{  position: 'relative' }}>



{success ? (




    <Box
    className="icon-success"
    >
     <CheckIcon />
     Senha alterada com sucesso!
    </Box> ) : error ? (
      <>
      <Box
      className="icon-error"
      >
        <PriorityHighOutlinedIcon/>
        Token inválido!
        <br/>
        <Typography variant="caption">
      Por favor, tente novamente ou entre em contato com nosso suporte.
      </Typography>
      </Box>

      </>

      ):(
        <Button
        variant="contained"
        disabled={loading}
        // onClick={() => setLoginStep(2)}
        type="submit"
      >
        Trocar senha
      </Button>
    )}

    {loading && (
      <CircularProgress
        className='loading-handler'
      />
    )}
  </Box>
    </Grid>
  </Grid>
</form>

  </Grid>
  
      
      ):(
<Grid  className="login-box-wrapper" size={{xl: 3, lg: 4, md: 5, sm: 6}}>

<img src={bepassLogo} alt="$" className="login-client-logo" />
<Typography variant="h2" gutterBottom>
  Qual aplicação?
</Typography>

      <ul className='login-app-list'>
        {applicationId === 7 ? (
 <li key={7} onClick={() => window.location.href = '/event'}>
 <i><AddchartOutlinedIcon /></i> Evento
</li>
        ):(
 <li key={3} onClick={() => window.location.href = '/home'}>
 <i><AddchartOutlinedIcon /></i> Jogo
</li>
        )}
      </ul>

      {/* <Typography variant="caption" onClick={() => setLoginStep(2)} gutterBottom sx={{ display: 'block' }}>
      voltar
      pedir acesso 
        </Typography> */}
  </Grid>
      )}
    </Grid>
  )
};

export default ResetPassword;
