import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, Chip, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';

import {Scanner} from '@yudiel/react-qr-scanner';

import FormControl, { useFormControl } from '@mui/material/FormControl';

import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent} from '../Components/ApiCall';

import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

type FormData = {
  id: number;
  hostname: string;
  serial_number: string;
  config_date: string;
  status: number;
  mac: string;
  ip_type: string;
  image_version: string;
  evento: string;
  ip: string;
  last_conf: string;
  ip_vpn: string;
  ip_wan: string;
  vpn: number;
  gw_default: string;
  dns1: string;
  dns2: string;
  vpn_file: string;
  gate: string;
  sector: string;
  beboxIP: string;
  deviceName: string;
  beboxName: string;
  deviceIp: string;
};



const InternalTerminals = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }



  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);
    
setTimeout(() => {
  setSuccess(true);
  setLoading(false);
}, 3000);
    console.log('Form data submitted:', data);
  };

  const [rola, setRola] = useState<any>([])
  const [vai, setVai] = useState<boolean>(true)
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openScaner, setOpenScaner] = React.useState(false);
    const handleOpenScaner = () => {setScanSession(1);setOpenScaner(true)};
    const handleCloseScaner = () => {setScanSession(1);setOpenScaner(false);};
    const [scanSession, setScanSession] = React.useState(1);
    
    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {
  try {
    const response = await apiEvent.get('/terminals');
    console.log(response.data);

    const dataValues = response.data.map((item:any, index:any) => {
      return {
        id: ++index,
        bebox_name: item.beboxName || 'lab_s2',
        server_name: item.serverName,
        device_name: item.deviceName,
        sector: item.sector,
        gate: item.gate,
        device_ip: item.deviceIP,
        device_firmware: item.deviceFirmware,
        bebox_version: item.beboxVersion,
        device_type: item.deviceType,
        device_group: item.deviceGroup,
        last_time_online: item.updatedAt,
        action: 'star',
        status: item.status,
        beboxIP: item.beboxIP,
        deviceName: item.deviceName,
        beboxName: item.beboxName,
      }
    })
    setRola(dataValues)
    setVai(false)

  } catch (error) {
    console.error(error);
  }
};


const getTerminalInfra = async (bebox_name:string) => {
  try {
    const response = await apiEvent.get(`/terminals/infra-detail/${bebox_name}`);
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};


const columns: GridColDef<any>[] = [
  { 
    field: 'device_name',
    headerName: 'Device',
    width: 110,
    editable: true,
    renderCell: (params) => {
      return <span className='single-status'>
        {params.row.status === 'active' ? (
          <WifiOutlinedIcon className='icon-active' />
        ):(
          <WifiOffOutlinedIcon className='icon-inactive' />
        )}
      {params.row.bebox_name}      
      </span>    },
  },{ 
    field: 'sector',
    headerName: 'Portarias',
    width: 90,
    editable: true,
  },{ 
    field: 'gate',
    headerName: 'Integração',
    width: 130,
    editable: true,
  },{ 
    field: 'device_ip',
    headerName: 'Device IP',
    width: 110,
    editable: true,
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 90,
    editable: true,
  },{
    field: 'device_group',
    headerName: 'Servidor',
    width: 90,
    editable: true,
  },{
    field: 'bebox_version',
    headerName: 'Versão bebox',
    width: 80,
    editable: true,
  },{
    field: 'device_firmware',
    headerName: 'Firmware',
    width: 150,
    editable: true,
  },{
    field: 'device_type',
    headerName: 'Tipo',
    width: 90,
    editable: true,
  },{
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
  },{
    field: 'status',
    headerName: 'Ativo',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return <Switch defaultChecked={params.value === 'active' ? true:false} color="primary" />
    },
  }
];


// ,{ 
//   field: 'sector',
//   headerName: 'Evento',
//   width: 90,
//   editable: true,
// }
// ,{
//   field: 'last_timex_online',
//   headerName: 'Última config',
//   sortable: false,
//   width: 120,
// },{
//   field: 'statusz',
//   headerName: 'Resetar',
//   sortable: false,
//   width: 140,
//   renderCell: (params) => {
//     return <Chip
//       label="Restar?"
//       deleteIcon={<RestartAltOutlinedIcon />}
//     />

//   },
// },

const handleScan = async (scanData:any) => {
  console.log(`loaded data data`, scanData[0]?.rawValue);

  setScanSession(2)
  // if(shouldScan){
  //   return true
  // }

  // setShouldScan(true)

};

useEffect(() => {
  fetchData()
}, [])

  return (
    <Box>

      <Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          Terminais
        </Typography>

        <Box>
        <Button variant="outlined" onClick={() => handleOpenScaner()}>Pesquisar</Button>
        <Button variant="contained" onClick={() => handleOpen()}>Cadastrar Novo</Button>
        </Box>

        <Modal
        open={openScaner}
        onClose={handleCloseScaner}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal custom-modal-device-search">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Pesquisar Device
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleCloseScaner()}>
        <CloseIcon
        />
        </i>
      </div>
      </Box>
      {scanSession === 1 ? (
        <Scanner
                   onScan={handleScan}
                   onError={(error:any) => console.log(error?.message)}/> 

      ):(
<Box className="device-search-result">
<Typography variant="h2" noWrap component="div">
    lab_02 <WifiOutlinedIcon className='icon-active' />
    <Typography variant="caption">online: 15/10/2024 03:15:45</Typography>

  </Typography>
   <TableContainer>
      <Table  size="small" aria-label="a dense table">
        <TableBody>
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              cliente
              </TableCell>
              <TableCell align="right">Bepass</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              evento
              </TableCell>
              <TableCell align="right">--</TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              serial_number
              </TableCell>
              <TableCell align="right">9407004067</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              ip
              </TableCell>
              <TableCell align="right">192.168.15.117</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              ip_type
              </TableCell>
              <TableCell align="right">dinamico</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              ip_vpn
              </TableCell>
              <TableCell align="right">10.0.8.176</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              server_host
              </TableCell>
              <TableCell align="right">srv-177</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              image_version
              </TableCell>
              <TableCell align="right">4.3</TableCell>
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              tipo
              </TableCell>
              <TableCell align="right">totem</TableCell>
            </TableRow>


            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              watch vers.
              </TableCell>
              <TableCell align="right">6.17</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    
</Box>
      )}
   

    </Box>
      </Modal>


        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Cadastrar Terminal
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleClose()}>
        <CloseIcon
        />
        </i>
      </div>
      </Box>

<form className='modal-form-content' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {success ? (
          <Grid  size={{ xs: 12, md: 6 }}>
        <Typography variant="subtitle1" component="h2">
          serial number: #9407004067 
        </Typography>
        </Grid>
        ):(
        <>
        <Grid  size={{ xs: 12, md: 6 }}>
        <FormControl fullWidth error={!!errors.image_version}>
            <InputLabel>Tipo de ip</InputLabel>
            <Select
              label="Tipo de ip"
              defaultValue=""
              {...register("image_version", { required: "Tipo de ip is required" })}
              onChange={(e) => setValue("image_version", e.target.value as string)}
            >
              <MenuItem value="1">Dinâmico</MenuItem>
              <MenuItem value="2">Estático</MenuItem>
            </Select>
            {errors.image_version && <FormHelperText>{errors.image_version.message}</FormHelperText>}
          </FormControl>
        </Grid>
{/* 
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="IP Facial"
            {...register("deviceIp", { required: "IP VPN é obrigatório" })}
            error={!!errors.deviceIp}
            helperText={errors.deviceIp?.message}
            fullWidth
          />
        </Grid> */}

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="DNS1"
            {...register("deviceIp", { required: "DNS1 é obrigatório" })}
            error={!!errors.deviceIp}
            helperText={errors.deviceIp?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="DNS2"
            {...register("deviceIp", { required: "DNS2 é obrigatório" })}
            error={!!errors.deviceIp}
            helperText={errors.deviceIp?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Tag Catraca"
            {...register("deviceName", { required: "Catraca é obrigatória" })}
            error={!!errors.deviceName}
            helperText={errors.deviceName?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Tag Facial"
            {...register("beboxName", { required: "Tag facial é obrigatória" })}
            error={!!errors.beboxName}
            helperText={errors.beboxName?.message}
            fullWidth
          />
        </Grid>


        <Grid  size={{ xs: 12, md: 6 }}>
        <FormControl fullWidth error={!!errors.image_version}>
            <InputLabel>Servidor</InputLabel>
            <Select
              label="Servidor"
              defaultValue=""
              {...register("image_version", { required: "Servidor is required" })}
              onChange={(e) => setValue("image_version", e.target.value as string)}
            >
              <MenuItem value="1">(santos) srv-177</MenuItem>
              <MenuItem value="2">(botafogo) srv-1517</MenuItem>
              <MenuItem value="3">(pameiras) dm-011</MenuItem>
            </Select>
            {errors.image_version && <FormHelperText>{errors.image_version.message}</FormHelperText>}
          </FormControl>
        </Grid></>
)}
        <Grid  size={{ xs: 12, md: 6 }}>
          {/* <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button> */}

<Box sx={{ m: 1, position: 'relative' }}>

{success ?

        <Box
        className="icon-success"
        >
         <CheckIcon />
         Salvo!
        </Box> :        <Button
          variant="contained"
          disabled={loading}
          // onClick={handleButtonClick}
          type="submit"
        >
          Criar terminal
        </Button>}

        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: "#64c800",
              position: 'absolute',
              top: '-50%',
              left: '40%',

            }}
          />
        )}
      </Box>
        </Grid>
      </Grid>
    </form>

        </Box>
      </Modal>

      </Box>


      <Box className="data-table-wrapper">
      
<DataGrid
        rows={rola}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        checkboxSelection
        disableRowSelectionOnClick
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(updatedRow, originalRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        loading={vai}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
      />
      </Box>
    </Box>
  )
};

export default InternalTerminals;