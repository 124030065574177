import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";

const ConfettiCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    let W = window.innerWidth;
    let H = window.innerHeight;
    const maxConfettis = 150;
    const particles: ConfettiParticle[] = [];

    const possibleColors = [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "Pink",
      "SlateBlue",
      "LightBlue",
      "Gold",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson",
    ];

    const randomFromTo = (from: number, to: number): number =>
      Math.floor(Math.random() * (to - from + 1) + from);

    class ConfettiParticle {
      x: number;
      y: number;
      r: number;
      d: number;
      color: string;
      tilt: number;
      tiltAngleIncremental: number;
      tiltAngle: number;

      constructor() {
        this.x = Math.random() * W;
        this.y = Math.random() * H - H;
        this.r = randomFromTo(11, 33);
        this.d = Math.random() * maxConfettis + 11;
        this.color = possibleColors[
          Math.floor(Math.random() * possibleColors.length)
        ];
        this.tilt = Math.floor(Math.random() * 33) - 11;
        this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
        this.tiltAngle = 0;
      }

      draw(context: CanvasRenderingContext2D) {
        context.beginPath();
        context.lineWidth = this.r / 2;
        context.strokeStyle = this.color;
        context.moveTo(this.x + this.tilt + this.r / 3, this.y);
        context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        context.stroke();
      }
    }

    const draw = () => {
      if (!context) return;

      requestAnimationFrame(draw);
      context.clearRect(0, 0, W, H);

      particles.forEach((particle) => {
        particle.draw(context);

        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;

        // Reposition particles that go out of bounds
        if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
          particle.x = Math.random() * W;
          particle.y = -30;
          particle.tilt = Math.floor(Math.random() * 10) - 20;
        }
      });
    };

    const resizeCanvas = () => {
      W = window.innerWidth;
      H = window.innerHeight;
      canvas.width = W;
      canvas.height = H;
    };

    // Add resize event listener
    window.addEventListener("resize", resizeCanvas);

    // Initialize particles
    for (let i = 0; i < maxConfettis; i++) {
      particles.push(new ConfettiParticle());
    }

    // Set initial canvas size
    canvas.width = W;
    canvas.height = H;

    // Start drawing
    draw();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return <Box className="conffeti-wrap">
    <canvas ref={canvasRef} style={{ display: "block" }} />
  </Box>;
};

export default ConfettiCanvas;